<template>
  <v-container>
    <div class="text-center my-5">
      <v-btn class="print_btn" @click="print()" x-large color="primary">
        <v-icon>print</v-icon>
      </v-btn>
    </div>

    <v-card class="mb-3" outlined v-for="(q, i) in qs" :key="i">
      <v-card-title>
        <v-chip small label color="#202020" class="white--text me-2">
          {{ i + 1 }}
        </v-chip>
        <h5>
          {{ q.text }}
        </h5>
      </v-card-title>
      <v-card-text>
        <p
          style="font-size: 17px"
          class="font-weight-bold"
          v-if="q.type == 'explain'"
        >
          {{ answers[i].text }}
        </p>

        <ul class="mr-4 mt-2" v-else-if="q.type == 'mcq'">
          <li
            class="font-weight-bold d-flex mb-3 justify-space-between"
            style="font-size: 15px"
            v-for="(op, x) in options_value[i]"
            :key="x"
          >
            <p class="font-weight-bold black--text">{{ op.text }}</p>
            <p
              class="font-weight-bold black--text"
              v-if="op.related_with_question"
            >
              السعر : {{ op.related_price }}
            </p>

            <p class="font-weight-bold black--text" v-else>
              السعر : {{ op.price }}
            </p>
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <div class="total pa-4 white--text text-end font-weight-bold">
      <span>الاجمالي : </span>
      {{ total }}
    </div>
  </v-container>
</template>

<script>
export default {
  name: "answers",
  data() {
    return {
      date: {
        total_cash: 10.0,
        Total_mastercars: 20.0,
        items: [
          {
            Delivery_name: "hassan",
            Delivery_id: 123,
            Total_cash: 10.0,
            Total_mastercars: 20.0,
          },
        ],
      },
      acco: 0,
      answers: [],
      questions: [],
      save_loader: false,
      total: 0,
      snackbar: {
        prop: false,
        err: false,
        errColor: "red lighteen-2",
        succColor: "success",
        errText: "",
        succText: "تم التسجيل بنجاح",
      },
    };
  },
  computed: {
    qs() {
      let questions = [];
      this.questions.forEach((e, i) => {
        if (e.related_with_question) {
          if (this.answers.length != 0) {
            let find_related = this.questions.find(
              (q) => q.id == e.related.related_id
            );
            let find_answers = this.answers.findIndex(
              (an) => an.q_id == find_related.id
            );

            if (find_related.type == "mcq") {
              for (let x = 0; x < e.related.options.length; x++) {
                if (
                  this.answers[find_answers].options.includes(
                    e.related.options[x]
                  )
                ) {
                  questions.push(e);
                  break;
                } else {
                  this.answers[i].options = [];
                  this.answers[i].text = null;
                }
              }
            } else if (find_related.type == "explain") {
              if (this.answers[find_answers].text == e.related.value) {
                questions.push(e);
              } else {
                this.answers[i].options = [];
                this.answers[i].text = null;
              }
            }
          }
        } else {
          questions.push(e);
        }
      });
      return questions;
    },
    related_q: (app) => (related_id) => {
      if (related_id) {
        let find_q = app.questions.find((e) => e.id == related_id);
        return find_q;
      } else {
        return false;
      }
    },
    acive_qs() {
      let questions = [];
      this.qs.forEach((e, i) => {
        let f_q = this.answers.find((a) => a.q_id == e.id);
        if (f_q) {
          questions.push(f_q);
        }
      });

      return questions;
    },
    empty_values() {
      let errors = [];
      this.acive_qs.forEach((e, i) => {
        if (e.type == "explain") {
          errors[i] = !this.answers[i].text;
        } else if (e.type == "mcq") {
          errors[i] = this.answers[i].options.length == 0;
        }
      });
      return {
        errors: errors,
        is_valid: !errors.includes(true),
      };
    },
    options_value() {
      let options = [];
      this.qs.forEach((e, i) => {
        if (e.type == "mcq") {
          options[i] = [];
          e.options.forEach((op, x) => {
            let option = this.answers[i].options.find((an_o) => an_o == op.id);
            if (option) {
              let related_price = 0;
              if (op.related_with_question) {
                let re_q_options = this.qs.findIndex(
                  (e) => e.id == op.related.q_id
                );
                if (op.related.options != 0) {
                  op.related.options_data.forEach((ff, gg) => {
                    let check_option = this.answers[
                      re_q_options
                    ].options.includes(ff.id);
                    if (check_option) {
                      let option = this.qs[re_q_options].options.find(
                        (s) => s.id == ff.id
                      );
                      related_price += ff.option_price * option.text;
                    }
                  });
                } else {
                  this.answers[re_q_options].options.forEach((ff, gg) => {
                    if (ff == this.qs[re_q_options].options[gg].id) {
                      related_price += this.qs[re_q_options].options[gg].price;
                    }
                  });
                }
              }
              options[i].push({
                text: op.text,
                price: op.price,
                related_price: related_price,
                related_with_question: op.related_with_question,
              });
            }
          });
        }
      });
      return options;
    },
  },
  methods: {
    print() {
      window.print();
    },
    get_form() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `clients/get_report/${this.$route.params.id}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          this.total = res.data.total;
          this.form_name = res.data.name;
          this.form_id = res.data.id;
          this.questions = Object.assign([], res.data.questions);
          let answers = res.data.answers;
          this.questions.forEach((e, i) => {
            let find_answer =
              answers.length != 0
                ? answers.answers.find((q) => q.q_id == e.id)
                : null;
            if (!find_answer) {
              this.$set(this.answers, i, {
                id: null,
                q_id: e.id,
                options: [],
                text: null,
                type: e.type,
              });
            } else {
              let is_last = e.id == answers.last_answer;
              if (is_last) {
                this.steps = i + 1;
              }
              this.$set(this.answers, i, {
                id: find_answer.id,
                q_id: e.id,
                options: find_answer.options,
                text: find_answer.text,
                last_answer: is_last,
                type: e.type,
              });
            }
          });
          if (!answers.last_answer) {
            this.answers[0].last_answer = true;
          } else {
            this.steps = this.last_q_index + 1;
          }
          this.data_loaded = true;
        })
        .catch((err) => {
          // this.$router.push("/occasions");
          console.log(err.response);
        });
    },
    save() {
      if (this.empty_values.is_valid) {
        if (!this.save_loader) {
          this.save_loader = true;
          this.$store
            .dispatch("public__request", {
              config: {
                url: "forms/save_answers",
                method: "post",
              },
              data: {
                occasion_id: this.$route.params.id,
                answers: this.answers,
              },
            })
            .then((res) => {
              this.snackbar.prop = true;
              this.snackbar.err = false;
              this.snackbar.succText = "تم تحديث الاجابات بنجاح";
              setTimeout(() => {
                this.$router.push("/occasions");
              }, 1300);
              console.log(res);
            })
            .catch((err) => {
              console.log(err.response);
            });
        }
      } else {
        this.snackbar.prop = true;
        this.snackbar.err = true;
        this.snackbar.errText = "برجاء ملئ الاجابات المطلوبة";
      }
    },
  },
  created() {
    this.get_form();
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .print_btn {
    display: none !important;
  }
}
.total {
  background: #202020;
}
</style>
